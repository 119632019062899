<template>
  <v-container>
  <h3>Antal HÄFTiga platser, SpleKfrågor och uppdrag lägger NätSäk in</h3>
  <h4>i databasen när vi vet :)</h4>
  </v-container>
</template>

<script>
export default {
  name: 'HaftigPlaces'
}
</script>

<style>

</style>